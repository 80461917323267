/*! Generated by Fontspring (https://www.fontspring.com) on October 23, 2019
*
* Fully installable fonts can be purchased at http://www.fontspring.com
*
* The fonts included in this stylesheet are subject to the End User License you purchased
* from Fontspring. The fonts are protected under domestic and international trademark and
* copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
* distributing this font software.
*
* (c) 2010-2019 Fontspring
*
*
*/

@font-face {
  font-family: 'Wreath Halftone';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('wreathhalftonemedium-webfont.woff2') format('woff2'),
    url('wreathhalftonemedium-webfont.woff') format('woff');
}
