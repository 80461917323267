/* Webfont: Swis721-Black */
@font-face {
  font-family: 'Swis721';
  src: url('swis721.woff2') format('woff2'), /* Modern Browsers */ url('swis721.woff2') format('woff');
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoLatin-BlackItalic */
@font-face {
  font-family: 'Swis721';
  src: url('swis721-italic.woff2') format('woff2'), /* Modern Browsers */ url('swis721-italic.woff2') format('woff');
  font-style: italic;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
