@import './reset.css';
@import './fonts/Lato/Lato.css';
@import './fonts/Certhas/Certhas.css';
@import './fonts/Wreath/wreath.css';
@import './fonts//Swis721/swis721.css';

html {
  scroll-behavior: smooth;
  overflow-x: clip;
  overscroll-behavior: none;
}

body {
  font-family: 'Lato', Arial, sans-serif;
  color: #313131;
}

img {
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
  visibility: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

.main-content {
  position: relative;

  // This is a bit meh and should use CSS variables, copied over from old CSS for now
  $header-height: 70px;
  $footer-height: 284px;

  @media (min-width: 768px) {
    min-height: calc(100vh - #{($header-height + $footer-height)});
  }

  .modal-open & {
    z-index: inherit;
  }
}

body:has([class^='BackToTopstyles__']) .envolve-sc-launcher,
.envolve-sc-chat-window {
  margin-bottom: 60px !important;
}

// needed to override the OneTrust logo size on iOS
@media (max-width: 767px) {
  #onetrust-pc-sdk .ot-pc-logo img {
    height: unset !important;
  }
}
