/* Webfont: LatoLatin-Black */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-Black.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-Black.woff') format('woff');
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-Black */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-black.woff2') format('woff2'), /* Modern Browsers */ url('../lato-extended/lato-black.woff') format('woff');
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-BlackItalic */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-BlackItalic.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-BlackItalic.woff') format('woff');
  font-style: italic;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-BlackItalic */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-blackitalic.woff2') format('woff2'),
    /* Modern Browsers */ url('../lato-extended/lato-blackitalic.woff') format('woff');
  font-style: italic;
  font-weight: 900;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-Heavy */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-Heavy.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-Heavy.woff') format('woff');
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-Heavy */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-heavy.woff2') format('woff2'), /* Modern Browsers */ url('../lato-extended/lato-heavy.woff') format('woff');
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-HeavyItalic */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-HeavyItalic.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-HeavyItalic.woff') format('woff');
  font-style: italic;
  font-weight: 800;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-HeavyItalic */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-heavyitalic.woff2') format('woff2'),
    /* Modern Browsers */ url('../lato-extended/lato-heavyitalic.woff') format('woff');
  font-style: italic;
  font-weight: 800;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-Bold */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-Bold.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-Bold */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-bold.woff2') format('woff2'), /* Modern Browsers */ url('../lato-extended/lato-bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-BoldItalic */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-BoldItalic.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-BoldItalic.woff') format('woff');
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-BoldItalic */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-bolditalic.woff2') format('woff2'),
    /* Modern Browsers */ url('../lato-extended/lato-bolditalic.woff2') format('woff');
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-Semibold */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-Semibold.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-Semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-Semibold */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-semibold.woff2') format('woff2'), /* Modern Browsers */ url('../lato-extended/lato-semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-SemiboldItalic */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-SemiboldItalic.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-SemiboldItalic.woff') format('woff');
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-SemiboldItalic */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-semibolditalic.woff2') format('woff2'),
    /* Modern Browsers */ url('../lato-extended/lato-semibolditalic.woff') format('woff');
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-Medium */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-Medium.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-Medium */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-medium.woff2') format('woff2'), /* Modern Browsers */ url('../lato-extended/lato-medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-MediumItalic */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-MediumItalic.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-MediumItalic.woff') format('woff');
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-MediumItalic */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-mediumitalic.woff2') format('woff2'),
    /* Modern Browsers */ url('../lato-extended/lato-mediumitalic.woff') format('woff');
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-Regular */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-Regular.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-Regular */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-regular.woff2') format('woff2'), /* Modern Browsers */ url('../lato-extended/lato-regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-Italic */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-Italic.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-Italic.woff') format('woff');
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-Italic */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-italic.woff2') format('woff2'), /* Modern Browsers */ url('../lato-extended/lato-italic.woff') format('woff');
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-Light */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-Light.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-Light.woff') format('woff');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-Light */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-light.woff2') format('woff2'), /* Modern Browsers */ url('../lato-extended/lato-light.woff') format('woff');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-LightItalic */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-LightItalic.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-LightItalic.woff') format('woff');
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-LightItalic */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-lightitalic.woff2') format('woff2'),
    /* Modern Browsers */ url('../lato-extended/lato-lightitalic.woff') format('woff');
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-Thin */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-Thin.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-Thin.woff') format('woff');
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-Thin */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-thin.woff2') format('woff2'), /* Modern Browsers */ url('../lato-extended/lato-thin.woff') format('woff');
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-ThinItalic */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-ThinItalic.woff2') format('woff2'), url('LatoLatin-ThinItalic.woff') format('woff');
  font-style: italic;
  font-weight: 200;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-ThinItalic */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-thinitalic.woff2') format('woff2'), url('../lato-extended/lato-thinitalic.woff') format('woff');
  font-style: italic;
  font-weight: 200;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-Hairline */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-Hairline.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-Hairline.woff') format('woff');
  font-style: normal;
  font-weight: 100;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-Hairline */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended/lato-hairline.woff2') format('woff2'), /* Modern Browsers */ url('../lato-extended/lato-hairline.woff') format('woff');
  font-style: normal;
  font-weight: 100;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}

/* Webfont: LatoLatin-HairlineItalic */
@font-face {
  font-family: 'Lato';
  src: url('LatoLatin-HairlineItalic.woff2') format('woff2'), /* Modern Browsers */ url('LatoLatin-HairlineItalic.woff') format('woff');
  font-style: italic;
  font-weight: 100;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: LatoExtended-HairlineItalic */
@font-face {
  font-family: 'Lato';
  src: url('../lato-extended//lato-hairlineitalic.woff2') format('woff2'),
    /* Modern Browsers */ url('../lato-extended//lato-hairlineitalic.woff') format('woff');
  font-style: italic;
  font-weight: 100;
  text-rendering: optimizeLegibility;
  font-display: swap;
  unicode-range: U+100-17F, U+180-24F, U+400-4FF;
}
